import { createI18n } from 'vue-i18n';


// Importa tus archivos de idioma
import es from './locales/es.json';
import pt from './locales/pt.json';


const messages = { es, pt };

// Crear la instancia de i18n
// const i18n = createI18n({
//   locale: 'pt', // Idioma por defecto
//   fallbackLocale: 'es', // Idioma de respaldo
//   messages,
//   // legacy: false, // Necesario para usar la Composition API en Vue 3
// });


// Obtener el idioma guardado en localStorage o usar 'es' por defecto
const savedLocale = localStorage.getItem('lang') || 'es';

// Crear la instancia de i18n
const i18n = createI18n({
  locale: savedLocale, //'pt', // Idioma por defecto
  fallbackLocale: 'es', // Idioma de respaldo
  messages,
  // legacy: false, // Necesario para usar la Composition API en Vue 3
});


export default i18n;
