import { h, resolveComponent } from 'vue'

const ListadoLiquidacion = () => import('@/app/views/liquidacion/views/ListadoLiquidacion');


const liquidacionRoutes = [
  {
    path: 'liquidacion',
    name: 'Liquidación',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      }
    },
    meta: {
      breadcrumb: {
        title: 'GLOBAL.BREADCRUMBS.liquidacion' // Clave de traducción para el breadcrumb principal
      }
    },
    children: [
      {
        path: '',
        name: 'liquidacion:Listado',
        component: ListadoLiquidacion,
        meta: {
          auth: true,
          entidad: 'liquidacion',
          breadcrumb: {
            title: 'GLOBAL.BREADCRUMBS.listado' // Clave de traducción para esta página
          }
        },
      }
    ]
  }
];

export default liquidacionRoutes;
